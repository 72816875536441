import React from 'react';
import { Hpadding } from "@fidize/ui.hpadding"
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Section } from '@fidize/ui.section'

export class Terms extends React.Component {
  render(){

        var privacy = <Flex type="column">
                        <Flex type="justify_center">
                            <Text type="black h2">Termini e Condizioni</Text>
                        </Flex>
                        <Vspace desk="40px" mobile="60px"></Vspace>
                        <Text type="black h3">
                        AMBITO APPLICATIVO
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        I termini e le condizioni generali qui riportati (di seguito: "T&C") si applicano a tutti gli ordini ed acquisti di prodotti di abbigliamento e accessori (di seguito: "Prodotti") sul sito web www.fidize.com (di seguito: "Sito Web") gestito da Moward s.r.l. , società con sede in Viale Giulio Cesare 47, 00192 Roma, Italia (di seguito: "Moward") da parte di persone residenti in paesi in cui il Sito Web è o sarà attivo (di seguito: "Cliente").
                        </Text>
                        <br></br>
                        <br></br>
                        <Vspace desk="30px" mobile="25px"></Vspace>

                        <Text type="black h3">
                        AVVERTENZE
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        Il Cliente è tenuto a leggere attentamente i T&C prima di effettuare qualsiasi ordine d'acquisto. Con l'accettazione dei T&C e l'invio di un ordine, il Cliente dichiara: 1- di aver letto, compreso ed accettato integralmente e senza riserve i T&C; 2- di autorizzare Moward ad immagazzinare, elaborare, usare e trasferire a terzi e/o all'estero i dati (personali) contenuti nell'ordine allo scopo di eseguire lo stesso. Moward fa del proprio meglio affinché le informazioni contenute nel Sito Web siano accurate ed aggiornate. Non è tuttavia possibile offrire garanzie in merito. Il Cliente riconosce ed accetta che i Prodotti potrebbero non essere del tutto conformi alle indicazioni, alle specifiche e/o alle fotografie presenti sul Sito Web.
                        </Text>
                        
                        <br></br>
                        <br></br>
                        <Vspace desk="30px" mobile="25px"></Vspace>
                        <Text type="black h3">
                        ORDINI
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        Il contratto di vendita tra Moward ed il Cliente è da ritenersi perfezionato con l'invio al Cliente, all'indirizzo e-mail indicato da quest'ultimo con l'ordine di acquisto, della conferma scritta della spedizione. Moward considererà eventuali richieste di cancellazione dell'ordine pervenute prima della spedizione dei Prodotti al Cliente. Nel caso in cui un determinato Prodotto presente nell'ordine del Cliente non fosse più disponibile, Moward non avrà nessun obbligo di fornitura del Prodotto in questione, ma sarà unicamente tenuta ad informare tempestivamente il Cliente di tale circostanza. Qualora fossero disponibili solo una parte dei Prodotti indicati nell'ordine, Moward contatterà il Cliente per chiedergli se intende annullare l'ordine o è disposto a ricevere soltanto i Prodotti disponibili; in tal caso al Cliente verrà addebitato solo l'importo relativo ai Prodotti che riceverà. Moward si riserva il diritto di ridurre, a sua libera discrezione, la quantità di Prodotti acquistabili sul Sito Web.
                        </Text>
                        <br></br>
                        <br></br>

                        <Vspace desk="30px" mobile="25px"></Vspace>
                        <Text type="black h3">
                        PREZZI
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        Tutti i prezzi degli articoli in vendita sono iva inclusa. ​I costi di spedizione e di consegna possono variare a seconda del paese e saranno addebitate al Cliente al momento della spedizione dei Prodotti ordinati, in base alle tariffe di spedizione in vigore riportate nella relativa sezione del Sito Web.
                        </Text>
                        <br></br>
                        <br></br>

                        <Vspace desk="30px" mobile="25px"></Vspace>
                        <Text type="black h3">
                        CONSEGNA
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        La consegna dei Prodotti sarà effettuata all'indirizzo di consegna indicato dal Cliente nell'ordine. In linea di principio i Prodotti saranno consegnati in 2-3 giorni lavorativi e comunque mai oltre 30 (trenta) giorni dalla conferma d'ordine da parte di Moward, fermo restando che i tempi di consegna possono variare a seconda del paese di destinazione. Nel caso in cui la consegna venisse ritardata per motivi non imputabili a Moward, al Cliente verrà comunicata, per quanto possibile, la nuova data di consegna.Moward si riserva il diritto di rifiutare, sospendere o annullare, a propria libera discrezione, la consegna o l'adempimento di una qualsiasi delle obbligazioni che le derivano dal contratto di vendita, in caso di presunta frode, inadempienza o violazione da parte del Cliente di obblighi assunti nei confronti di Moward con precedenti ordini e/o contratti di vendita. Se i Prodotti ordinati richiederanno l'attraversamento della dogana (in base al paese di destinazione) ed il Cliente deciderà di non ricevere piu' l'ordine una volta che questo è giunto in dogana, Moward non concederà il rimborso della merce la quale verrà abbandonata sul posto.
                        </Text>
                        <br></br>
                        <br></br>

                        <Vspace desk="30px" mobile="25px"></Vspace>
                        <Text type="black h3">
                        DIRITTO DI RECESSO
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        Il Cliente ha la facoltà di restituire i Prodotti acquistati su www.fidize.com, senza l'addebito di nessuna penale e senza essere tenuto a darne motivazione, entro quattordici (14) giorni di calendario a partire dal giorno di ricevimento da parte del Cliente dei Prodotti in oggetto, rispettivamente entro il termine previsto dalle norme imperative applicabili nel paese dove risiede il Cliente, se questo è più lungo. Con il ricevimento dei Prodotti da parte di Moward, il contratto di vendita si riterrà annullato a tutti gli effetti e tutte le relative obbligazioni, diritti o rivendicazioni decadranno, fermo restando che se i Prodotti resi a Moward risulteranno danneggiati, Moward avrà il diritto di compensare tale danno con il pagamento dei Prodotti già effettuato dal Cliente. Oltre a quanto sopra indicato, il diritto di recesso è soggetto alle seguenti condizioni:
                        <br></br>
                        <br></br>
                        - Entro quattordici (14) giorni di calendario dalla data di ricevimento dei Prodotti da parte del Cliente, il Cliente potrà richiedere l'autorizzazione a procedere con il reso direttamente attraverso il sito, nell'apposita sezione "Resi".
                        <br></br>
                        <br></br>
                        - Dopo aver ricevuto l'autorizzazione al reso, i Prodotti dovranno essere rispediti a Moward, a rischio e a spese del cliente (salvo accordi differenti tra Moward ed il Cliente), con un invio unico a mezzo corriere o posta entro 5 (cinque) giorni dalla data del ricevimento dell'autorizzazione, al seguente indirizzo: Moward srl - Viale Leonardo Da Vinci - 01021 Acquapendente (VT) - Italia. Si richiede di indicare in modo visibile sul pacco del reso sia il destinatario Moward che il nominativo del Cliente, al fine di evitarne lo smarrimento; in caso di mancato recapito del pacco restituito, Moward chiederà al cliente la prova di avvenuta consegna, ovvero il cedolino riportante la firma di colui che ha ricevuto il pacco restituito. Se il cliente non fornirà la prova di consegna, Moward non potrà concedere il rimborso.
                        <br></br>
                        <br></br>
                        - Il Prodotto che viene reso non dovrà essere stato usato, consumato, o danneggiato.
                        <br></br>
                        <br></br>
                        - Il cartellino identificativo del Prodotto dovrà essere ancora attaccato allo stesso.
                        <br></br>
                        <br></br>
                        - Il Prodotto deve essere restituito comprensivo di tutti le etichette, confezioni ed accessori originali (dustbags, grucce, copriabiti etc.) ricevuti insieme all’ordine. Tutte le calzature ed accessori devono essere restituiti insieme alla loro scatola originale, che è considerata a tutti gli effetti parte integrante del prodotto stesso e che non deve essere stata in nessun modo danneggiata e/o alterata, né utilizzata come unico imballaggio esterno. Non appena in grado di confermare che le condizioni previste sono state soddisfatte, Moward invierà al Cliente una email di conferma dell'accettazione del reso e darà avvio alla procedura di rimborso di quanto pagato per i Prodotti resi entro i 3 (tre) giorni successivi, indipendentemente dalla modalità di pagamento adottata in precedenza dal Cliente. Il rimborso degli importi pagati dal Cliente sarà sempre effettuato esclusivamente a favore del Cliente che ha effettuato il pagamento. I tempi di accredito del rimborso possono variare in relazione alla modalità scelta. Moward non si ritiene responsabile per eventuali ritardi nei riaccrediti, laddove il rimborso sia gestito da terzi (come, ad esempio, PayPal). Nel caso in cui le condizioni di cui sopra non dovessero essere state rispettate, il Cliente non avrà diritto al rimborso dell'importo pagato a Moward. Il Cliente avrà tuttavia unicamente il diritto di ritirare i Prodotti resi mediante spedizione via corriere, a proprio rischio e a proprie spese. Se il Cliente non chiede di riavere indietro i Prodotti resi entro trenta (30) giorni dalla relativa comunicazione da parte di Moward, Moward avrà il diritto di trattenerli unitamente all'importo ricevuto in relazione alla transazione.
                        </Text>

                        <br></br>
                        <br></br>
                        <Vspace desk="30px" mobile="25px"></Vspace>
                        <Text type="black h3">
                        GARANZIA E LIMITAZIONE DI RESPONSABILITA'
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        La garanzia di Moward è limitata ad eventuali difetti manifesti dei Prodotti - esclusa, senza limitazioni, ogni garanzia implicita in ordine alla qualità e all'idoneità allo scopo per il Cliente - per un periodo di 2 (due) anni dalla consegna dei Prodotti.La garanzia da unicamente diritto ad ottenere il rimborso dell'importo pagato per i Prodotti difettosi, purché il difetto venga comunicato a Moward entro 2 (due) mesi da quando è stato riscontrato. Oltre a quanto sopra indicato, il diritto al rimborso è soggetto alle seguenti condizioni, sotto pena di decadenza della garanzia: - Prima di rispedire indietro i Prodotti difettosi a Moward, il Cliente dovrà richiedere l'autorizzazione a procedere con il reso direttamente attraverso il sito, tramite l'apposita sezione "Contattaci". - I Prodotti difettosi verranno ritirati da Moward, a proprie spese. Una volta ricevuto il Prodotto ed appurata la difettosità dello stesso, Moward invierà al Cliente una email di conferma dell'accettazione del reso e darà avvio alla procedura di rimborso a favore del Cliente del prezzo pagato per il Prodotto difettoso. Il rimborso degli importi pagati dal Cliente sarà sempre fatto esclusivamente a favore del Cliente che ha effettuato il pagamento. Fatto salva la garanzia di cui sopra, Moward esclude ogni e qualsiasi responsabilità (contrattuale o extracontrattuale) nei confronti del Cliente, nei limiti delle norme imperative applicabili. Il limite massimo di responsabilità complessiva a carico di Moward non potrà in alcun caso superare l'importo corrisposto dal Cliente per l'acquisto del Prodotto.
                        </Text>

                        <br></br>
                        <br></br>
                        <Vspace desk="30px" mobile="25px"></Vspace>
                        <Text type="black h3">
                        DISPOSIZIONI GENERALI
                        </Text>
                        <br></br>
                        <Text type="darkgrey normal">
                        Nel caso in cui una o più disposizioni di questi T&C dovesse risultare nulla, annullabile, illegale o inefficace, ciò non comporterà l'inefficacia totale dei T&C. La disposizione in questione verrà sostituita da un'analoga disposizione valida che tenga debitamente conto dell'intenzione e del contenuto della stessa, nel rispetto ed ai sensi delle normative vigenti.
                        </Text>
                    </Flex>


        var desk =  <Section padding="1" bkg="grey">
                        <Hpadding padding="1">
                            {privacy}
                        </Hpadding>
                    </Section>

      var mobile =  <Section padding="1" bkg="grey">
                        <Hpadding padding="mobile0">
                            {privacy}
                        </Hpadding>
                    </Section>

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}

import * as React from "react";
import { Terms } from "../components/terms/terms";

const PrivacyPage = () => {
    return (
        <>
            <head>
                <title>Fidize - Termini e condizioni</title>
            </head>
            <Terms />
        </>
    )
}

export default PrivacyPage
